@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Inter" !important;
  font-weight: 400;
}

.dialog-container {
  max-width: 600px;
  padding: 20px;
}

.dialog-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.dialog-left {
  flex: 1;
  text-align: center;
}

.overseer-image {
  width: 120px; /* Increased size */
  height: 120px;
  margin-bottom: 10px;
}

.dialog-title {
  font-size: 22px;
  font-weight: bold;
  color: #3576c2;
}

.dialog-subtitle {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.dialog-separator {
  width: 4px;
  height: 100px; /* Increased height */
  background-color: white;
  border-radius: 2px;
}

.dialog-right {
  flex: 1;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  text-align: right;
}

.pb-1 {
  padding-bottom: 1rem;
}

.submit-button {
  background-color: #3576c2 !important;
  color: white !important;
  border: none;
  border-radius: 8px !important;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600 !important;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) {
  body {
    font-size: 16px !important;
  }

  h4 {
    font-size: 24px !important;
  }

  h6 {
    font-size: 16px !important;
  }
  button {
    font-size: 16px !important;
  }

  .btn-mtable-filter {
    padding: 0.5rem !important;
  }
}

.mh-2 {
  margin: 2.5rem auto;
}

.mh-1 {
  margin: 1rem auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-login-image {
  /* background: url("../public/logo.jpg"); */
  background-position: center;
  background-size: cover;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.p-dropdown-panel {
  z-index: 10000 !important;
}
