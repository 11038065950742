.error_template {
    padding: 40px 15px;
    text-align: center;
}

.error_actions {
    margin-top: 15px;
    margin-bottom: 15px;
}

.error_actions .btn {
    margin-right: 10px;
}