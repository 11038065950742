@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Inter" !important;
  font-weight: 400;
}

.dialog-container {
  max-width: 600px;
  padding: 20px;
}

.dialog-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}

.dialog-left {
  flex: 1 1;
  text-align: center;
}

.overseer-image {
  width: 120px; /* Increased size */
  height: 120px;
  margin-bottom: 10px;
}

.dialog-title {
  font-size: 22px;
  font-weight: bold;
  color: #3576c2;
}

.dialog-subtitle {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.dialog-separator {
  width: 4px;
  height: 100px; /* Increased height */
  background-color: white;
  border-radius: 2px;
}

.dialog-right {
  flex: 1 1;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  text-align: right;
}

.pb-1 {
  padding-bottom: 1rem;
}

.submit-button {
  background-color: #3576c2 !important;
  color: white !important;
  border: none;
  border-radius: 8px !important;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600 !important;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) {
  body {
    font-size: 16px !important;
  }

  h4 {
    font-size: 24px !important;
  }

  h6 {
    font-size: 16px !important;
  }
  button {
    font-size: 16px !important;
  }

  .btn-mtable-filter {
    padding: 0.5rem !important;
  }
}

.mh-2 {
  margin: 2.5rem auto;
}

.mh-1 {
  margin: 1rem auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-login-image {
  /* background: url("../public/logo.jpg"); */
  background-position: center;
  background-size: cover;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.p-dropdown-panel {
  z-index: 10000 !important;
}

/*modified from a code made by Mary Gathoni*/
.sidenav_sidenavparent__1GDKd{
    width:"60px";
}
.sidenav_sidenavparent__1GDKd:hover{
    width:"15vw";
}
.sidenav_sidenavHover__2Ksz8 {
    width: 15vw;
    transition: width 0.3s ease-in-out;
    height: 100vh;
    background-color: rgb(10,25,41);
    padding-top: 28px;  
    position: "fixed"; 
    z-index: 1; 
    top: 0;
    left: 0;
}

.sidenav_sidenav__38DfB {
    /*transition: width 0.3s ease-in-out;*/
    height: 100vh;
    background-color: rgb(10,25,41);
    padding-top: 28px;  
    position: "fixed"; 
    z-index: 1; 
    top: 0;
    left: 0;
    width: 60px;
}

.sidenav_sideitem__1am6W {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    cursor: pointer;
    color: #B2BAC2;
    text-decoration: none;
}


.sidenav_hideMenuSide__2BN47 {
    display: flex;
    align-items: center;
    padding: 8px 20px 8px 17px;
    cursor: pointer;
    color: #B2BAC2;
    text-decoration: none;
}

.sidenav_sideitem__1am6W:hover{
    padding: 8px 20px;
    border-radius: 10px;
    text-decoration: none;
    color:white;
    font-weight:700;
    background: linear-gradient(to right, #2E5CFF, #0AACF5);
    border-top-right-radius:0;
    border-bottom-right-radius:0;
    border-top-left-radius:8px;
    border-bottom-left-radius:8px;
}

.sidenav_sideitemChild__2qqTI{
    transition: height 0.3s, opacity 0.5s ease-in-out;
    margin-top: 0.5em;
    margin-left: 1.5em;
    opacity: 1;
    display:block;
}

.sidenav_sideitemChild__2qqTI:hover{
    transition: height 0.3s, opacity 0.5s ease-in-out;
    margin-top: 0.5em;
    margin-left: 1.5em;
    opacity: 1;
    font-weight:700;
    background: linear-gradient(to right, #2E5CFF, #0AACF5);
    border-top-right-radius:0;
    border-bottom-right-radius:0;
    border-top-left-radius:8px;
    border-bottom-left-radius:8px;
}

.sidenav_sideitemChildClosed__33-pL{
    display:none;
    margin-top:0;
    opacity: 0;
    height: 0;
}
.sidenav_linkText__1WaD7{
    padding-left: 16px;
    opacity: 1;
    display:block;
}

.sidenav_linkTextHidden__3qqy4{
    padding-left: 16px;
    opacity: 0;
    display:none;
    transition: opacity 0.5s ease-in-out;
}

.sidenav_menuBtn__QqpC6 {
    align-self: center;
    align-self: flex-start; 
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 20px;
}
.NotFound_error_template__1fEdW {
    padding: 40px 15px;
    text-align: center;
}

.NotFound_error_actions__768uF {
    margin-top: 15px;
    margin-bottom: 15px;
}

.NotFound_error_actions__768uF .NotFound_btn__3We3U {
    margin-right: 10px;
}
